var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              treeTitle: _vm.$t(`cip.plat.intf.interface.field.title`),
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c("head-layout", {
                        attrs: {
                          "head-btn-options": _vm.headBtnOptions,
                          "head-title": _vm.$t(
                            `cip.plat.intf.interface.title.indexHeadTitle`
                          ),
                        },
                        on: {
                          "head-remove": _vm.headRemove,
                          "head-add-tabs": _vm.headAddTabs,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowDetail,
                  "row-remove": _vm.rowRemove,
                  "row-edit": _vm.rowEdit,
                },
              }),
            ],
            1
          ),
          _vm.showTreeDialog
            ? _c(
                "el-dialog",
                {
                  directives: [
                    { name: "dialog-drag", rawName: "v-dialog-drag" },
                  ],
                  staticClass: "qmDialog",
                  attrs: {
                    title: _vm.$t(
                      `cip.plat.intf.interface.title.headCateTitle`
                    ),
                    modal: false,
                    "modal-append-to-body": false,
                    visible: _vm.showTreeDialog,
                    width: "60%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showTreeDialog = $event
                    },
                  },
                },
                [
                  _c("avue-form", {
                    ref: "addForm",
                    attrs: { option: _vm.treeDialogOption },
                    on: { submit: _vm.treeNodeSave },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  }),
                  _c(
                    "span",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small" }, on: { click: _vm.cancel } },
                        [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.celBtn`)))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleTreeNodeSave },
                        },
                        [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.defBtn`)))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }